import React from "react";

class Shop extends React.Component {
  render() {
    return (
      <section id="shop">
        <h1>Shop</h1>
      </section>
    );
  }
}

export default Shop;
