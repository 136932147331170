import React from "react";

class Timelapse extends React.Component {
  render() {
    return (
      <section id="timelapse">
        <h1>Zeitraffer</h1>
      </section>
    );
  }
}

export default Timelapse;
